<div class="flex justify-between">
  <div class="inline-flex rounded-md shadow-sm mb-4 self-end">
    <button class="button" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate">
      <mat-icon class="chevron_icon">chevron_left</mat-icon>
    </button>
    <button class="secondary-button px-4 mx-1 today-button" mwlCalendarToday [(viewDate)]="viewDate">Today</button>
    <button class="button" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate">
      <mat-icon class="chevron_icon">chevron_right</mat-icon>
    </button>
  </div>
  <div>
    <h3 class="week-date">{{ viewDate | calendarDate: view + 'ViewTitle' : 'en' }}</h3>
  </div>
  @if (hasUserEquipment) {
    <button mat-raised-button class="button mb-4 self-end" (click)="openCreateEventPopup()">
      <mat-icon svgIcon="calendar" class="icon-white"></mat-icon>
      <span>{{ 'MAIN.HOME.CREATE_EVENT' | translate }}</span>
    </button>
  }
</div>

<mwl-calendar-week-view
  class="pointer-events-auto"
  [viewDate]="viewDate"
  [hourSegments]="1"
  [hourSegmentHeight]="hourSegmentHeight()"
  [events]="calendarEvents"
  (eventClicked)="openEventDetailsPopup($event)"
  [headerTemplate]="headerTemplate"
  [eventTitleTemplate]="eventTitleTemplate"
>
</mwl-calendar-week-view>

<ng-template #headerTemplate let-days="days" let-locale="locale" let-dayClicked="dayClicked" let-eventDropped="eventDropped">
  <div class="cal-day-headers cursor-default">
    @for (day of days; track $index) {
      <div
        class="cal-header"
        [class.cal-past]="day.isPast"
        [class.cal-future]="day.isFuture"
        [class.cal-drag-over]="day.dragOver"
        (mwlClick)="dayClicked.emit({ date: day.date })"
        mwlDroppable
        (dragEnter)="day.dragOver = true"
        (dragLeave)="day.dragOver = false"
        (drop)="day.dragOver = false; eventDropped.emit({ event: $event.dropData.event, newStart: day.date, fromHeader: true })"
      >
        <div class="top" [class.today]="day.isToday">
          <div class="day-label text-uppercase">
            {{ day.date | customCalendarDate }}
          </div>
          <!-- <div class="day-number">{{ day.date | calendarDate: 'weekViewColumnSubHeader' : locale }}</div> -->
        </div>
      </div>
    }
  </div>
</ng-template>

<ng-template #eventTitleTemplate let-event="event" let-view="view">
  <p class="cal-event-title event-title pt-2" [innerHTML]="event.title | calendarEventTitle: view : event"></p>
  <p class="cal-event-title event-study-type pt-3">{{ event.meta.studyType }}</p>
</ng-template>
