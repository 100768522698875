import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCalendarDate',
  standalone: true,
})
export class CalendarDatePipe implements PipeTransform {
  transform(value: Date | string): string {
    if (!value) return '';

    const date = new Date(value);
    const options: Intl.DateTimeFormatOptions = { weekday: 'long' };

    // Get the day of the month
    const day = date.getDate();

    // Format the weekday using Intl.DateTimeFormat
    const weekday = new Intl.DateTimeFormat('en-US', options).format(date);

    // Combine weekday and day
    return `${weekday} ${day}`;
  }
}
