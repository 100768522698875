import { Component, computed, HostListener, Input, OnInit, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from '../../services/message.service';
import { TranslateService } from '@ngx-translate/core';
import { CreateEventDialogComponent } from 'src/app/create-event-dialog/create-event-dialog.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { DetailsEventDialogComponent } from 'src/app/main/home/details-event-dialog/details-event-dialog.component';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { ScheduleLiveService } from '../../services/schedule-live.service';
import { SharedModule } from '../../shared.module';
import { CalendarDatePipe } from '../../pipes/calendar-date.pipe';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  imports: [SharedModule, CalendarDatePipe],
})
export class CalendarComponent implements OnInit {
  @Input() calendarEvents: CalendarEvent[] = [];
  @Input() hasUserEquipment: boolean | null = true;
  @Input() isMedinboxEquipment!: boolean;

  viewDate: Date = new Date();
  view: CalendarView = CalendarView.Week;
  screenHeight = signal(0);

  hourSegmentHeight = computed(() => (30 > (this.screenHeight() - 260) / 24 ? 30 : (this.screenHeight() - 260) / 24));

  constructor(
    public dialog: MatDialog,
    private errorService: MessageService,
    private translate: TranslateService,
    private scheduleLiveService: ScheduleLiveService,
  ) {}

  ngOnInit() {
    this.screenHeight.set(window.innerHeight);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenHeight.set(window.innerHeight);
  }

  openCreateEventPopup() {
    const dialogRef = this.dialog.open(CreateEventDialogComponent, {
      minWidth: '700px',
      maxWidth: '1000px',
      maxHeight: '90vh',
      data: {
        dialogTitle: this.translate.instant('SCHEDULED_LIVE.CREATE_EVENT'),
        isEdit: false,
        isMedinboxEquipment: this.isMedinboxEquipment,
      },
      panelClass: 'event-modal',
    });
    dialogRef.afterClosed().subscribe((liveId) => {
      if (liveId != null) {
        this.errorService.showSuccess('Event successfully created');
      }
    });
  }

  openEventDetailsPopup($event: any) {
    let event = this.calendarEvents.map((event) => event.meta).find((event) => event.liveId == $event.event.meta.liveId);
    const dialogRef = this.dialog.open(DetailsEventDialogComponent, {
      minWidth: '300px',
      maxWidth: '500px',
      maxHeight: '90vh',
      data: event,
      panelClass: 'event-modal',
    });
    dialogRef.afterClosed().subscribe((option: string) => {
      if (option == 'edit') {
        this.editEvent(event);
      } else if (option == 'delete') {
        this.deleteEvent(event);
      }
    });
  }

  deleteEvent(event: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '300px',
      data: {
        yesNoDialog: true,
        isWhite: true,
        isDelete: true,
        title: this.translate.instant('MAIN.HOME.DELETE_EVENT_TITLE'),
        isCaseDesign: true,
        buttonCancelText: this.translate.instant('GLOBAL.CANCEL'),
        buttonConfirmText: this.translate.instant('MY_LIBRARY.DELETE'),
        subtitle: this.translate.instant('MAIN.HOME.DELETE_EVENT_SUBTITLE'),
      },
      panelClass: 'white-modal',
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res && event?.liveId) {
        // Remove the scheduleLive
        this.scheduleLiveService.deleteScheduledLive(event.liveId).subscribe((bool) => {
          if (bool) {
            this.errorService.showSuccess('event successfully deleted');
          }
        });
      }
    });
  }

  editEvent(event: any) {
    if (event) {
      const dialogRef = this.dialog.open(CreateEventDialogComponent, {
        minWidth: '700px',
        maxWidth: '1000px',
        maxHeight: '90vh',
        data: {
          dialogTitle: this.translate.instant('SCHEDULED_LIVE.EDIT_EVENT'),
          liveId: event.liveId,
          isEdit: true,
          isMedinboxEquipment: this.isMedinboxEquipment,
          formData: {
            physician: event.physician,
            studyType: event.studyType,
            title: event.title,
            equipmentId: event.equipmentId,
            reference: event.reference,
            organizerEmail: event.organizerEmail,
            description: event.description,
            date: event.startDate,
            startHour: new Date(event.startDate),
            endHour: new Date(event.endDate),
            cohostEmails: event.cohostEmails,
            contacts: event.contacts,
          },
        },
        panelClass: 'event-modal',
      });
      dialogRef.afterClosed().subscribe((liveId) => {
        if (liveId != null) {
          this.errorService.showSuccess('Event successfully updated');
        }
      });
    }
  }
}
