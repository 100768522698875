@if (userService.user$ | async; as user) {
  <div class="upcoming-card w-full py-2 px-5 my-3">
    <div class="flex justify-between">
      <div class="mb-2">
        <h3 class="startDate mb-2">{{ upcomingEvent.startDate | date: 'EEEE dd MMM YYYY, hh:mm aa' }}</h3>
        <h2 class="study-title">{{ upcomingEvent.title }}</h2>
      </div>
      @if (upcomingEvent.organizerEmail == user?.email || this.isMedinboxEquipment) {
        <button #t="matMenuTrigger" mat-icon-button [matMenuTriggerFor]="menu" class="justify-self-end more_icon">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="editEvent()">
            <mat-icon svgIcon="edit-video" class="icon"></mat-icon>
            <span>{{ 'MAIN.HOME.EDIT_EVENT' | translate }}</span>
          </button>
          <button mat-menu-item (click)="deleteEvent()">
            <mat-icon svgIcon="delete" class="delete-icon"></mat-icon>
            <span>{{ 'MAIN.HOME.DELETE_EVENT' | translate }}</span>
          </button>
        </mat-menu>
      }
    </div>

    <div class="columns-2 my-2">
      <div class="physician">{{ upcomingEvent.physician }}</div>
      <div class="organization flex justify-center">
        <mat-icon svgIcon="pin" class="pin-icon mr-2"></mat-icon>
        <span>{{ upcomingEvent.organizationName }}</span>
      </div>
    </div>
    <div class="description my-3">{{ upcomingEvent.description }}</div>
    <mat-chip-listbox class="my-2">
      @for (invite of upcomingEvent.cohostEmails; track invite) {
        <mat-chip class="chip">
          <span class="chip-text">{{ invite }}</span>
        </mat-chip>
      }
    </mat-chip-listbox>
    <mat-chip-listbox class="mt-2 mb-4">
      @for (invite of upcomingEvent.contacts; track invite) {
        <mat-chip class="chip">
          <span class="chip-text">{{ invite }}</span>
        </mat-chip>
      }
    </mat-chip-listbox>
    @if (upcomingEvent.liveState == LiveStateEnum.InProgress) {
      <button mat-raised-button class="secondary-button blue-text" (click)="joinSession()">
        <span>{{ 'ACCOUNT.LOGIN.JOIN' | translate }}</span>
      </button>
    } @else if (upcomingEvent.liveState == LiveStateEnum.Scheduled && isMedinboxEquipment) {
      <button mat-raised-button class="secondary-button blue-text" (click)="startSession()">
        <span>{{ 'LIVE.JOIN_LIVE.START' | translate }}</span>
      </button>
    }
  </div>
}
