export interface Timezone {
  value: string;
  viewValue: string;
}

export const TIMEZONES: Timezone[] = [
  { value: 'Etc/GMT-12', viewValue: 'GMT-12' },
  { value: 'Etc/GMT-11', viewValue: 'GMT-11' },
  { value: 'Pacific/Honolulu', viewValue: 'GMT-10 Pacific/Honolulu' },
  { value: 'America/Anchorage', viewValue: 'GMT-9 America/Anchorage' },
  { value: 'America/Los_Angeles', viewValue: 'GMT-8 America/Los_Angeles' },
  { value: 'America/Denver', viewValue: 'GMT-7 America/Denver' },
  { value: 'America/Chicago', viewValue: 'GMT-6 America/Chicago' },
  { value: 'America/New_York', viewValue: 'GMT-5 America/New_York' },
  { value: 'America/Halifax', viewValue: 'GMT-4 America/Halifax' },
  { value: 'America/Sao_Paulo', viewValue: 'GMT-3 America/Sao_Paulo' },
  { value: 'Atlantic/South_Georgia', viewValue: 'GMT-2 Atlantic/South_Georgia' },
  { value: 'Atlantic/Azores', viewValue: 'GMT-1 Atlantic/Azores' },
  { value: 'Europe/London', viewValue: 'GMT+0 Europe/London' },
  { value: 'Europe/Paris', viewValue: 'GMT+1 Europe/Paris' },
  { value: 'Europe/Athens', viewValue: 'GMT+2 Europe/Athens' },
  { value: 'Europe/Moscow', viewValue: 'GMT+3 Europe/Moscow' },
  { value: 'Asia/Dubai', viewValue: 'GMT+4 Asia/Dubai' },
  { value: 'Asia/Karachi', viewValue: 'GMT+5 Asia/Karachi' },
  { value: 'Asia/Dhaka', viewValue: 'GMT+6 Asia/Dhaka' },
  { value: 'Asia/Bangkok', viewValue: 'GMT+7 Asia/Bangkok' },
  { value: 'Asia/Shanghai', viewValue: 'GMT+8 Asia/Shanghai' },
  { value: 'Asia/Tokyo', viewValue: 'GMT+9 Asia/Tokyo' },
  { value: 'Australia/Sydney', viewValue: 'GMT+10 Australia/Sydney' },
  { value: 'Pacific/Noumea', viewValue: 'GMT+11 Pacific/Noumea' },
  { value: 'Pacific/Fiji', viewValue: 'GMT+12 Pacific/Fiji' },
];
